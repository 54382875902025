<template>
  <div id="FAQ">
    <h1>Tulip FAQ</h1>
    <p>Q</p>
    <p>A</p>
  </div>

</template>

<script>

export default {
  name: 'FAQ',
  components: {
  },
  data(){
    return{
    }
  }
}
</script>
<style scoped>
#FAQ{
  color: #fff;
  margin: 2rem auto;
  width: 100%;
  min-height: 100vh;
}
.chain{
  color: #fff;
  font-size: 15px;
  transition: 1s;
  font-weight: bold;
  cursor:pointer;
}

</style>
